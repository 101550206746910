import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet'
import Cookies from "js-cookie";
import {Input, Button, Form, App, Row, Col, Divider, Typography, Space, Switch} from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { TiArrowForwardOutline } from "react-icons/ti";
import validator from "validator";
import { ActionType, API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

// Commons
import Intro from '../../commons/intro'

const { App: { title }, Pages: { SignIn }, App: AppLanguage, Validation, Message: MessageLanguage } = language
const { SET_USER, SET_ACCESS_TOKEN } = ActionType
const { USER_MANAGEMENT_AUTH_SIGN_IN, USER_MANAGEMENT_PROFILE_MY_ACCOUNT, USER_MANAGEMENT_AUTH_SIGN_IN_GOOGLE, USER_MANAGEMENT_AUTH_OTP_CODE } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function Index() {

    const [otp, setOTP] = useState(false);
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const { dispatch } = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const navigate = useNavigate()
    const location = useLocation()
    const { message } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
    }, []);

    const onFinish = ({ username, password, otp, captcha }: { username: string, password?: string, otp: boolean, captcha: string }) => {
        if (!password) {
            axios.get(USER_MANAGEMENT_AUTH_OTP_CODE + username).then(() => {
                setOTP(false)
                message.success(MessageLanguage[Message.OTP_SUCCESS]).then(response => response)
            }, async (error: AxiosError) => {
                const { response: { data } } = error;
                form.resetFields(['captcha'])
                // @ts-ignore
                message.error(MessageLanguage[data.message]).then(response => response )
            })
        } else {
            axios.post(USER_MANAGEMENT_AUTH_SIGN_IN, { username, password, otp, captcha }).then((response: { data: { accessToken: string }}) => {
                const { data: { accessToken } } = response
                Cookies.set('accessToken', accessToken, { expires: 365 })
                dispatch({ type: SET_ACCESS_TOKEN , payload: { accessToken }})
                axios.get(USER_MANAGEMENT_PROFILE_MY_ACCOUNT).then((response: { data: { } }) => {
                    const { data: user } = response
                    dispatch({ type: SET_USER , payload: { user }})
                    navigate(location.state?.from?.pathname || "/", { replace: true });
                    message.success(MessageLanguage[Message.SIGN_IN_PROFILE]).then(response => response)
                })
            }, async (error: AxiosError) => {
                const { response: { data } } = error;
                form.resetFields(['captcha'])
                // @ts-ignore
                message.error(MessageLanguage[data.message]).then(response => response )
            })
        }
    };

    return (
        <div>
            <Helmet>
                <title>{title} - {SignIn.title}</title>
            </Helmet>
            <Row>
            <Col xs={24} sm={24} lg={24} xl={14} xxl={9}>
               <Intro />
            </Col>
            <Col xs={24} sm={24} lg={24} xl={10} xxl={15} >
                <Link className={'link-button link-button-mobile'} to={'/sign-up'}><Space><span>{SignIn.signUp}</span><TiArrowForwardOutline size={20} /></Space></Link>
                <div className={'sign-in'}>
                    <Space direction="vertical" size="middle">
                        <Typography.Title>{SignIn.title}</Typography.Title>
                        <Button icon={<img src={require('../../resources/media/social/google.png')} width={24}/>} block onClick={() => window.location.replace(`${baseURL}${USER_MANAGEMENT_AUTH_SIGN_IN_GOOGLE}`)}>{SignIn.google}</Button>
                        <Divider>{SignIn.or}</Divider>
                        <Form layout={'vertical'} form={form} name="sign-in" autoComplete="off" onFinish={onFinish}>
                            <Form.Item
                                label={SignIn.email}
                                name="username"
                                rules={[
                                    { required: true, message: `${SignIn.email} ${Validation.required}` },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || validator.isEmail(value)) return Promise.resolve()
                                            else return Promise.reject(new Error(Validation.invalidEmail))
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder={SignIn.emailPlaceholder} />
                            </Form.Item>
                            {
                                !otp && (
                                    <Form.Item
                                        label={SignIn.password}
                                        name="password"
                                        extra={<Link to={'/reset-password'}>{SignIn.forgotPassword}</Link>}
                                        rules={[
                                            { required: true, message: `${SignIn.password} ${Validation.required}` },
                                        ]}
                                    >
                                        <Input.Password placeholder={SignIn.passwordPlaceholder} />
                                    </Form.Item>
                                )
                            }
                            <Space align="center">
                                <Form.Item
                                    label={SignIn.otp}
                                    name="otp"
                                    valuePropName="checked"
                                    initialValue={otp}
                                >
                                    <Switch onChange={(checked: boolean) => setOTP(checked)} />
                                </Form.Item>
                                <Typography.Text className={'switch'}>{SignIn.enableOTP}</Typography.Text>
                            </Space>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        block
                                        htmlType="submit"
                                    >
                                        { !otp ? SignIn.signIn : SignIn.sendOTP }
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>
                    </Space>
                </div>
            </Col>
        </Row>
        </div>
    )
}
