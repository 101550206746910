import React from "react";
import { Flex } from "antd";

import language from "../../resources/languages/en_US";

const { Commons: { Intro } } = language

export default function Index() {
    return (
        <Flex gap="small" vertical={true} justify={'center'} align={'center'} className={'intro'}>
            <div className={'intro-title'}>
                <img alt={Intro.title} src={require('../../resources/media/logo-white.png')} />
            </div>
            <div className={'intro-description'}>
                <p>{Intro.description}</p>
            </div>
        </Flex>
    )
}
