import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet'
import { Input, Button, Form, App, Row, Col, Typography, Space } from "antd";
import { useParams, Link, useNavigate } from "react-router-dom";
import { TiArrowForwardOutline } from "react-icons/ti";
import validator from "validator";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

// Commons
import Intro from '../../commons/intro'

const { App: { title }, Pages: { ResetPassword }, App: AppLanguage, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_AUTH_REQUEST_RESET_PASSWORD, USER_MANAGEMENT_AUTH_RESET_PASSWORD } = API
const { Message } = Enum

export default function Index() {

    const [form] = Form.useForm()
    const [sendEmail, setSendEmail] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [, forceUpdate] = useState({})
    const { token } = useParams()
    const { axios } = useContext(AxiosContext)
    const { message } = App.useApp()
    const navigate = useNavigate()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({});
    }, []);

    const onFinish = ({ email, password }: { email: string, password: string }) => {
        if (token) {
            axios.patch(USER_MANAGEMENT_AUTH_RESET_PASSWORD, { password, token }).then((response: {}) => {
                setResetPassword(true)
            }, async (error: AxiosError) => {
                const { response: { data } } = error;
                setResetPassword(true)
                form.resetFields(['captcha'])
                // @ts-ignore
                message.error(MessageLanguage[data.message]).then(response => response )
            })
        } else {
            axios.post(USER_MANAGEMENT_AUTH_REQUEST_RESET_PASSWORD, { email }).then((response: {}) => {
                setSendEmail(true)
            }, async (error: AxiosError) => {
                const { response: { data } } = error;
                setSendEmail(true)
                form.resetFields(['captcha'])
                // @ts-ignore
                message.error(MessageLanguage[data.message]).then(response => response )
            })
        }
    };

    return (
        <div>
            <Helmet>
                <title>{title} - {ResetPassword.title}</title>
            </Helmet>
            <Row>
                <Col xs={24} sm={24} lg={24} xl={14} xxl={9}>
                    <Intro />
                </Col>
                <Col xs={24} sm={24} lg={24} xl={10} xxl={15} >
                    <Link className={'link-button link-button-mobile'} to={'/sign-in'}><Space><span>{ResetPassword.signIn}</span><TiArrowForwardOutline size={20} /></Space></Link>
                    <div className={'sign-in reset-password'}>
                        <Space direction="vertical" size="middle">
                            <Typography.Title>{ResetPassword.title}</Typography.Title>
                            {
                                (!sendEmail && !resetPassword) ? (
                                    <Form layout={'vertical'} form={form} name="sign-in" autoComplete="off" onFinish={onFinish}>
                                        {
                                            token ? (
                                                <>
                                                    <Form.Item
                                                        label={ResetPassword.password}
                                                        name="password"
                                                        rules={[
                                                            { required: true, message: `${ResetPassword.password} ${Validation.required}` },
                                                        ]}
                                                    >
                                                        <Input.Password placeholder={ResetPassword.passwordPlaceholder} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={ResetPassword.passwordConfirm}
                                                        name="confirm"
                                                        dependencies={['password']}
                                                        rules={[
                                                            { required: true, message: `${ResetPassword.passwordConfirm} ${Validation.required}` },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (!value || getFieldValue('password') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error(Validation.passwordConfirm));
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input.Password placeholder={ResetPassword.passwordConfirmPlaceholder} />
                                                    </Form.Item>
                                                </>
                                            ) : (
                                                <Form.Item
                                                    label={ResetPassword.email}
                                                    name="email"
                                                    rules={[
                                                        { required: true, message: `${ResetPassword.email} ${Validation.required}` },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || validator.isEmail(value)) return Promise.resolve()
                                                                else return Promise.reject(new Error(Validation.invalidEmail))
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input placeholder={ResetPassword.emailPlaceholder} />
                                                </Form.Item>
                                            )
                                        }
                                        <Form.Item shouldUpdate>
                                            {() => (
                                                <Button
                                                    block
                                                    htmlType="submit"
                                                    style={{marginTop:15}}
                                                >
                                                    { ResetPassword.resetPassword }
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </Form>
                                ) : resetPassword ? (
                                    <>
                                        <Typography.Text className={'notice'}>{ResetPassword.resetPasswordNotice}</Typography.Text>
                                        <Button block onClick={() => navigate('/sign-in')}>{ResetPassword.signIn}</Button>
                                    </>
                                ) : sendEmail ? (
                                    <>
                                        <Typography.Text className={'notice'}>{ResetPassword.sendEmailNotice}</Typography.Text>
                                        <Button icon={<img src={require('../../resources/media/social/gmail.png')} width={24}/>} block onClick={() => window.location.replace('https://mail.google.com/')}>{ResetPassword.gmail}</Button>
                                        <Button icon={<img src={require('../../resources/media/social/outlook.png')} width={24}/>} block onClick={() => window.location.replace('https://login.live.com/')}>{ResetPassword.outlook}</Button>
                                        <Button icon={<img src={require('../../resources/media/social/yahoo.png')} width={24}/>} block onClick={() => window.location.replace('https://mail.yahoo.com/')}>{ResetPassword.yahoo}</Button>
                                    </>
                                ) : null
                            }
                        </Space>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
