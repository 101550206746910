import React, { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { isMobileOnly, isDesktop } from "react-device-detect"
import Cookies from "js-cookie";
import { Layout, Button, theme, App, Affix, Avatar, Dropdown, Space, Typography } from 'antd'
import { FaSignOutAlt, FaBars, FaTimes, FaRegUser } from "react-icons/fa";
import type { MenuProps } from 'antd'
import { ActionType, Enum, API } from "../../resources/constants";
import language from "../../resources/languages/en_US";

// Contexts
import GlobalContext from '../../contexts/global'

const { Header } = Layout
const { Message: MessageLanguage, Layouts: { Dashboard } } = language
const { SET_USER, SET_ACCESS_TOKEN } = ActionType
const { DOWNLOAD } = API
const { Message } = Enum
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function Index({ collapsed, setCollapsed }: { collapsed: boolean, setCollapsed: (collapsed: boolean) => void }) {

    const { token: { colorBgContainer } } = theme.useToken()
    const { user, accessToken, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate()
    const { message } = App.useApp()

    const collapse = (
        <Button
            icon={collapsed ? <FaTimes /> : <FaBars />}
            onClick={() => setCollapsed(!collapsed)}
            className='collapsed-mobile'
        />
    )

    const items: MenuProps['items'] = [
        {
            key: 'user',
            label: user && <Typography.Text>{user.firstName} {user.lastName}</Typography.Text>,
        },
        {
            key: 'sign-out',
            label: (
                <Typography.Link
                    onClick={() => {
                        Cookies.remove('accessToken')
                        dispatch({ type: SET_ACCESS_TOKEN , payload: { accessToken: null }})
                        dispatch({ type: SET_USER , payload: { user: null }})
                        message.success(MessageLanguage[Message.SIGN_OUT_PROFILE]).then(response => response)
                        navigate("/")
                    }}
                >
                    <Space>
                        <Typography.Text>{Dashboard.signOut}</Typography.Text>
                        <FaSignOutAlt />
                    </Space>
                </Typography.Link>
            ),
        }
    ]

    return (
        <Header style={{background: colorBgContainer}}>
            {
                isMobileOnly ? (
                    <div style={{position:'absolute'}}>
                        <Affix offsetTop={12}>
                            {collapse}
                        </Affix>
                    </div>
                ) : null
            }
            <Space>
                <div className={'header-logo'}>
                    <img alt={Dashboard.title} src={require('../../resources/media/logo-white.png')} />
                </div>
                <Dropdown menu={{items}} placement="bottomRight" arrow>
                    <Avatar size={45} icon={user?.avatar ? <img src={baseURL + DOWNLOAD + user.avatar + `?width=45&height=45&token=${accessToken}`} width={45} /> : <FaRegUser />} />
                </Dropdown>
            </Space>
        </Header>
    )
}
