export enum Message {
    SIGN_IN_PROFILE = "SIGN_IN_PROFILE",
    SIGN_OUT_PROFILE = "SIGN_OUT_PROFILE",
    CHANGE_SUCCESS = "CHANGE_SUCCESS",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
    UPLOAD_ERROR = "UPLOAD_ERROR",
    DELETE_MEMBER = "DELETE_MEMBER",
    ADD_MEMBER = "ADD_MEMBER",
    INVITE_MEMBER = "INVITE_MEMBER",
    DELETE_ACCOUNT = "DELETE_ACCOUNT",
    OTP_SUCCESS = "OTP_SUCCESS",
}

export enum DefaultSource {
    ITEM_1 = "ITEM_1",
}

export enum SetAsideCode {
    ITEM_1 = "ITEM_1",
    ITEM_2 = "ITEM_2",
    ITEM_3 = "ITEM_3",
    ITEM_4 = "ITEM_4",
    ITEM_5 = "ITEM_5",
    ITEM_6 = "ITEM_6",
    ITEM_7 = "ITEM_7",
    ITEM_8 = "ITEM_8",
    ITEM_9 = "ITEM_9",
    ITEM_10 = "ITEM_10",
    ITEM_11 = "ITEM_11",
    ITEM_12 = "ITEM_12",
    ITEM_13 = "ITEM_13",
    ITEM_14 = "ITEM_14",
    ITEM_15 = "ITEM_15",
    ITEM_16 = "ITEM_16",
    ITEM_17 = "ITEM_17",
    ITEM_18 = "ITEM_18",
    ITEM_19 = "ITEM_19",
    ITEM_20 = "ITEM_20",
    ITEM_21 = "ITEM_21",
    ITEM_22 = "ITEM_22",
    ITEM_23 = "ITEM_23",
}

export enum NoticeType {
    ITEM_1 = "ITEM_1",
    ITEM_2 = "ITEM_2",
    ITEM_3 = "ITEM_3",
    ITEM_4 = "ITEM_4",
    ITEM_5 = "ITEM_5",
    ITEM_6 = "ITEM_6",
    ITEM_7 = "ITEM_7",
    ITEM_8 = "ITEM_8",
    ITEM_9 = "ITEM_9",
}

export enum Plan {
    FELIX_PRO = 'FELIX_PRO',
    FELIX_PRIME = 'FELIX_PRIME',
}

export enum Member {
    MEMBER = 'MEMBER',
    INVITE = 'INVITE',
}
