import React, {useContext, useState, useEffect, MouseEvent} from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { Row, Col, Input, Button, Space, Pagination, Card, Flex, Typography, Tag, Form, DatePicker, Checkbox, Select, AutoComplete, Empty, App } from 'antd'
import { TiArrowForwardOutline } from "react-icons/ti";
import { FaDownload, FaRegBell, FaRegCalendar, FaDollarSign, FaPercent, FaFilter } from "react-icons/fa";
import dayjs, { Dayjs } from 'dayjs'
import { API, Enum, Regex } from "../../resources/constants"
import language from "../../resources/languages/en_US"

// Interfaces
import { Tender, Filter } from "../../interfaces/pages/opportunities"
import { ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Opportunities }, Share, Validation, Message: MessageLanguage, Enum: EnumLanguage } = language
const { TENDER_SEARCH, TENDER_NAICS_CODE_SEARCH, TENDER_BUYER_SEARCH, TENDER_PSC_CODE_SEARCH, USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION } = API;
const { SetAsideCode, NoticeType, DefaultSource } = Enum
const { isNumber } = Regex
const PVB = ['Max', 'Min'];
const PTS = ['Max', 'Min'];

export default function Index() {

    const { user } = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const [pagination, setPagination] = useState<{
        page: number,
        pageSize: number,
        total: number,
    }>({
        page: 1,
        pageSize: 6,
        total: 0,
    });
    const [search, setSearch] = useState<string>('');
    const [filter, setFilter] = useState<Filter>({});
    const [naicsCodes, setNAICSCodes] = useState<string[]>([]);
    const [pscCodes, setPSCCodes] = useState<string[]>([]);
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [buyerOptions, setBuyerOptions] = useState<{ name: string, agency_subelement: string }[]>([]);
    const [buyerExcludeOptions, setBuyerExcludeOptions] = useState<{ name: string, agency_subelement: string }[]>([]);
    const [pscCodeOptions, setPSCCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const [tenders, setTenders] = useState<Tender[]>([])
    const [loadingTender, setLoadingTender] = useState<boolean>(true)
    const [loadingProfileFilter, setLoadingProfileFilter] = useState<boolean>(false)
    const { message } = App.useApp();

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({})
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getTender()
    }

    const getTender = async (filter: Filter = { naicsCodes, pscCodes }, params = {
        page: 1,
        pageSize: pagination.pageSize,
    }) => {
        const body: Filter = {}
        if (filter.titleKeyword) body.titleKeyword = filter.titleKeyword
        // @ts-ignore
        if (filter.defaultSource) body.defaultSource = EnumLanguage.DefaultSource[filter.defaultSource]
        if (filter.active) body.status = 'active'
        if (filter.inactive) body.status = 'inactive'
        if (filter.dueDate) body.dueDate = dayjs(filter.dueDate).tz(process.env.REACT_APP_TZ).format('YYYY-MM-DD')
        if (filter.postedDate) body.publishDate = dayjs(filter.postedDate).tz(process.env.REACT_APP_TZ).format('YYYY-MM-DD')
        if (filter.solicitationNumber) body.noticeId = filter.solicitationNumber
        if (filter.buyer) body.agency = filter.buyer
        if (filter.buyerExclude) body.agencyExclude = filter.buyerExclude
        if (!_.isEmpty(filter.naicsCodes)) body.naicsCode = filter.naicsCodes
        if (!_.isEmpty(filter.pscCodes)) body.pscCode = filter.pscCodes
        // @ts-ignore
        if (filter.noticeType && !_.isEmpty(filter.noticeType)) body.noticeType = filter.noticeType.map((noticeType: string) => EnumLanguage.NoticeType[noticeType])
        // @ts-ignore
        if (filter.setAside && !_.isEmpty(filter.setAside)) body.setAside = filter.setAside.map((setAside: string) => (setAside === SetAsideCode.ITEM_23) ? 'null' : EnumLanguage.SetAsideCode[setAside])

        setFilter(filter)
        return axios.post(TENDER_SEARCH, { ...body, page: params.page - 1, size: params.pageSize }).then((response: { data: {
                results: Tender[],
                total: number,
            }}) => {
            const { data: { results: tenders, total} } = response
            setTenders(tenders)
            setPagination({
                ...params,
                total,
            })
            setLoadingTender(false)
        }).catch((error: AxiosError) => {
            const { response: { data } } = error;
            setTenders([])
            setPagination({
                page: 1,
                pageSize: 6,
                total: 0,
            })
            setLoadingTender(false)
            // @ts-ignore
            message.error(MessageLanguage[data.message]||data.message).then(response => response )
        })
    }

    const getClassifications = async () => {
        setLoadingProfileFilter(true)
        await axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, { params: { loading: false } }).then((response: { data: ClassificationsForm }) => {
            const { data: { naicsCodes = [], pscCodes = [], setAsideCodes = []} } = response
            if (!_.isEmpty(naicsCodes)) {
                setNAICSCodeOptions([])
                setNAICSCodes(naicsCodes);
            }
            if (!_.isEmpty(pscCodes)) {
                setPSCCodeOptions([])
                setPSCCodes(pscCodes);
            }
            if (!_.isEmpty(setAsideCodes)) {
                // @ts-ignore
                form.setFieldsValue({ setAside: setAsideCodes.map((setAsideCode: string) => Object.keys(EnumLanguage.SetAsideCode).find(key => EnumLanguage.SetAsideCode[key] === setAsideCode)) });
            }
        }).catch( (error: {}) => error )
        setLoadingProfileFilter(false)
    }

    const onFinish = (async (filter: Filter) => {
        setLoadingTender(true)
        setSearch('')
        await getTender({ ...filter, naicsCodes, pscCodes })
    })

    return (
        <div className={'opportunities'}>
            <Helmet>
                <title>{title} - {Opportunities.title}</title>
            </Helmet>
            <Row>
                    <Col xs={24} sm={24} lg={24} xl={20}>
                        <div className={'search'}>
                            <Space direction={'vertical'} size={'large'} className={'search-form'}>
                                <Input placeholder={Opportunities.Search.searchPlaceholder} size="large" value={search} onChange={({ target: { value }}) => setSearch(value)} suffix={
                                    <Button
                                        loading={loadingTender}
                                        onClick={async () => {
                                            setLoadingTender(true)
                                            setPSCCodeOptions([])
                                            setBuyerOptions([])
                                            setBuyerExcludeOptions([])
                                            setNAICSCodeOptions([])
                                            setNAICSCodes([])
                                            setPSCCodes([])
                                            form.resetFields()
                                            await getTender({ titleKeyword: search, naicsCodes: [], pscCodes: []  })
                                        }}
                                    >
                                        {Opportunities.Search.searchButton}
                                    </Button>
                                } />
                                <Space className={'search-button'}>
                                    <Button type="link" className={'link-button'}><Space><FaDownload size={15} /><span>{Opportunities.Search.export}</span></Space></Button>
                                    <Button type="link" className={'link-button'}><Space><FaRegBell size={15} /><span>{Opportunities.Search.createSchedule}</span></Space></Button>
                                </Space>
                            </Space>
                            {
                                !_.isEmpty(tenders) ? (
                                    <>
                                        <Row gutter={20} className={'search-result'}>
                                            {
                                                tenders.map((tender: Tender) => {
                                                    const keywords = tender.keywords ? Array.isArray(tender.keywords) ? tender.keywords : tender.keywords.split(',') : [];
                                                    return (
                                                        <Col xs={24} sm={24} lg={24} xl={12}>
                                                            <Card title={(
                                                                <Space direction={"vertical"} size={25}>
                                                                    <Typography.Link
                                                                        href={tender.link}
                                                                        target={'_blank'}
                                                                        ellipsis={true}
                                                                        className={'title'}
                                                                    >{tender.title}</Typography.Link>
                                                                    <Space size={50}>
                                                                        <Space className={'search-icon'}><FaRegCalendar size={14} /><span>Due: {tender.dueDate && dayjs(tender.dueDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')}</span></Space>
                                                                        <Space className={'search-icon'}><FaDollarSign size={14} /><span>PVB: {PVB[(Math.floor(Math.random() * PVB.length))]}</span></Space>
                                                                        <Space className={'search-icon'}><FaPercent size={14} /><span>PTS: {PTS[(Math.floor(Math.random() * PTS.length))]}</span></Space>
                                                                    </Space>
                                                                    <Typography.Paragraph ellipsis={{rows:1, suffix: ""}}>{tender.description}</Typography.Paragraph>
                                                                </Space>
                                                            )}>
                                                                <Space direction={"vertical"} size={20} style={{width:'100%'}}>
                                                                    <Space className={'search-tag'} key={'tag'}>
                                                                        <Typography.Paragraph ellipsis={{rows: 2, suffix: ""}}>
                                                                            { keywords.map((keyword: string) => (keyword ? <Tag color="#0B1821">{keyword.toString().trim()}</Tag> : null)) }
                                                                        </Typography.Paragraph>
                                                                    </Space>
                                                                    <Space className={'search-button'} key={'button'}>
                                                                        <Button type="link" className={'link-button'}><Space><span>{Opportunities.Search.askAI}</span><TiArrowForwardOutline size={20} /></Space></Button>
                                                                        <Button><Space><span>{Opportunities.Search.selectContract}</span></Space></Button>
                                                                    </Space>
                                                                </Space>
                                                            </Card>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                        <Flex justify={'center'}>
                                            <Pagination
                                                defaultCurrent={pagination.page}
                                                current={pagination.page}
                                                pageSize={pagination.pageSize}
                                                showSizeChanger={false}
                                                total={pagination.total}
                                                itemRender={ (_, type, originalElement) => {
                                                    if (type === 'prev') {
                                                        return <a>{Share.prev}</a>;
                                                    }
                                                    if (type === 'next') {
                                                        return <a>{Share.next}</a>;
                                                    }
                                                    return originalElement;
                                                }}
                                                onChange={async (page, pageSize) => {
                                                    setLoadingTender(true)
                                                    await getTender(filter, {
                                                        page,
                                                        pageSize,
                                                    })
                                                }}
                                            />
                                        </Flex>
                                    </>
                                ) : <Empty description={Share.noResults} />
                            }
                        </div>
                    </Col>
                    <Col xs={24} sm={24} lg={24} xl={4}>
                        <div className={'filter'}>
                            <div className={'title'}>
                                <span>{Opportunities.Filter.title}</span>
                            </div>
                            <Space direction={'vertical'} className={'filter-item'} size={20}>
                                <Button type="link" block={true} loading={loadingProfileFilter} className={'link-button'} onClick={() => getClassifications()}><Space><FaFilter size={15} /><span>{Opportunities.Filter.useProfileFilter}</span></Space></Button>
                                <Form layout="vertical" form={form} name="filter-opportunities" onFinish={onFinish}>
                                    <Form.Item
                                        label={Opportunities.Filter.defaultSource}
                                        name="defaultSource"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            size={'large'}
                                            placeholder={Share.pleaseSelect}
                                            optionFilterProp="label"
                                            options={Object.values(DefaultSource).map((item) => ({ label: EnumLanguage.DefaultSource[item], value: item}))}
                                            />
                                    </Form.Item>
                                    <Form.Item
                                        name="active"
                                        valuePropName="checked"
                                        dependencies={['inactive']}
                                        initialValue={false}
                                        style={{marginBottom:5}}
                                    >
                                        <Checkbox onChange={({ target: { checked} }) => checked && form.getFieldValue('inactive') && form.setFieldValue('inactive', false)}>{Opportunities.Filter.activeOnly}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="inactive"
                                        valuePropName="checked"
                                        dependencies={['active']}
                                        initialValue={false}
                                    >
                                        <Checkbox onChange={({ target: { checked} }) => checked && form.getFieldValue('active') && form.setFieldValue('active', false)}>{Opportunities.Filter.inactiveOnly}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.dueDate}
                                        name="dueDate"
                                    >
                                        <DatePicker
                                            allowClear={true}
                                            placeholder={Share.selectDate}
                                            size={'large'}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.postedDate}
                                        name="postedDate"
                                    >
                                        <DatePicker
                                            allowClear={true}
                                            placeholder={Share.selectDate}
                                            size={'large'}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.solicitationNumber}
                                        name="solicitationNumber"
                                    >
                                        <Input
                                            placeholder={Opportunities.Filter.solicitationNumberPlaceholder}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.buyer}
                                        name="buyer"
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.buyerPlaceholder}
                                            size="large"
                                            options={buyerOptions.map(({ name, agency_subelement }) => ({ label: (
                                                <Space direction={'vertical'} size={0}>
                                                    <Typography.Title level={3}>{agency_subelement}</Typography.Title>
                                                    <Typography.Title level={4}>{name}</Typography.Title>
                                                </Space>
                                                ), value: name }))}
                                            onSearch={(query) => axios.get(TENDER_BUYER_SEARCH, { params: { query, page: 0, size: 10, loading: false } }).then((response: { data: { results: { agency_subelement: string, name: string, type: string }[], total: number } }) => {
                                                const { data: { results, total } } = response
                                                if (query) setBuyerOptions(results.map(({ name, agency_subelement}) => ({ name, agency_subelement })))
                                                else setBuyerOptions([])
                                            }).catch( (error: {}) => setBuyerOptions([]) )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.buyerExclude}
                                        name="buyerExclude"
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.buyerPlaceholder}
                                            size="large"
                                            options={buyerExcludeOptions.map(({ name, agency_subelement }) => ({ label: (
                                                    <Space direction={'vertical'} size={0}>
                                                        <Typography.Title level={3}>{agency_subelement}</Typography.Title>
                                                        <Typography.Title level={4}>{name}</Typography.Title>
                                                    </Space>
                                                ), value: name }))}
                                            onSearch={(query) => axios.get(TENDER_BUYER_SEARCH, { params: { query, page: 0, size: 10, loading: false } }).then((response: { data: { results: { agency_subelement: string, name: string, type: string }[], total: number } }) => {
                                                const { data: { results, total } } = response
                                                if (query) setBuyerExcludeOptions(results.map(({ name, agency_subelement}) => ({ name, agency_subelement })))
                                                else setBuyerExcludeOptions([])
                                            }).catch( (error: {}) => setBuyerExcludeOptions([]) )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.naicsCode}
                                        name="naicsCode"
                                        rules={[
                                            { pattern: isNumber, message: `${Opportunities.Filter.naicsCode} ${Validation.number}` },
                                        ]}
                                        extra={!_.isEmpty(naicsCodes) && (
                                            <div className={'tags'}>
                                                {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                                    const naicsCodesCopy = [...naicsCodes]
                                                    naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                                    setNAICSCodes(naicsCodesCopy)
                                                }}>{naicsCode}</Tag>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.naicsCodePlaceholder}
                                            size="large"
                                            options={naicsCodeOptions}
                                            onSearch={(query) => axios.get(TENDER_NAICS_CODE_SEARCH, { params: { query, page: 0, size: 10, loading: false } }).then((response: { data: { results: { code: string, description: string, id: string, title: string, year: number }[], total: number } }) => {
                                                const { data: { results, total } } = response
                                                if (query) setNAICSCodeOptions(results.map(({ code, title }) => ({ label: `${code} - ${title}`,value: code })))
                                                else setNAICSCodeOptions([])
                                            }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                                            onBlur={(event: any) => {
                                                const naicsCode = event?.target?.value;
                                                if (naicsCode && naicsCode.match(isNumber)) {
                                                    setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
                                                    form.resetFields(['naicsCode']);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.pscCode}
                                        name="pscCode"
                                        extra={!_.isEmpty(pscCodes) && (
                                            <div className={'tags'}>
                                                {pscCodes.map((pscCode: string) => <Tag key={pscCode} color="#4BAB71" closable onClose={(event: MouseEvent) => {
                                                    const pscCodesCopy = [...pscCodes]
                                                    pscCodesCopy.splice(pscCodesCopy.indexOf(pscCode), 1)
                                                    setPSCCodes(pscCodesCopy)
                                                }}>{pscCode}</Tag>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.pscCodePlaceholder}
                                            size={'large'}
                                            options={pscCodeOptions}
                                            onSearch={(query) => axios.get(TENDER_PSC_CODE_SEARCH, { params: { query, page: 0, size: 10, loading: false } }).then((response: { data: { results: { PSCCode: string, ProductAndServiceCodeName: string }[], total: number } }) => {
                                                const { data: { results, total } } = response
                                                if (query) setPSCCodeOptions(results.map(({ PSCCode, ProductAndServiceCodeName }) => ({ label: `${PSCCode} - ${ProductAndServiceCodeName}`,value: PSCCode })))
                                                else setPSCCodeOptions([])
                                            }).catch( (error: {}) => setPSCCodeOptions([]) )}
                                            onBlur={(event: any) => {
                                                const pscCode = event?.target?.value;
                                                if (pscCode) {
                                                    setPSCCodes(_.uniq([...pscCodes, pscCode]));
                                                    form.resetFields(['pscCode']);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.noticeType}
                                        name="noticeType"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            size={'large'}
                                            placeholder={Opportunities.Filter.noticeTypePlaceholder}
                                            optionFilterProp="label"
                                            options={Object.values(NoticeType).map((item) => ({ label: EnumLanguage.NoticeType[item], value: item}))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.setAsideCode}
                                        name="setAside"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            size={'large'}
                                            placeholder={Opportunities.Filter.setAsideCodePlaceholder}
                                            optionFilterProp="label"
                                            options={Object.values(SetAsideCode).map((item) => ({ label: EnumLanguage.SetAsideCode[item], value: item}))}
                                        />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                htmlType="submit"
                                                block={true}
                                                loading={loadingTender}
                                                >
                                                {Opportunities.Filter.applyFilter}
                                            </Button>
                                        )}
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                type="link"
                                                block={true}
                                                loading={loadingTender}
                                                className={'link-button'}
                                                onClick={async () => {
                                                    setLoadingTender(true)
                                                    setSearch('')
                                                    setPSCCodeOptions([])
                                                    setBuyerOptions([])
                                                    setBuyerExcludeOptions([])
                                                    setNAICSCodeOptions([])
                                                    setNAICSCodes([])
                                                    setPSCCodes([])
                                                    form.resetFields()
                                                    await getTender({ naicsCodes: [], pscCodes: [] })
                                                }}
                                            >
                                                {Opportunities.Filter.clearFilter}
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Form>
                            </Space>
                        </div>
                    </Col>
                </Row>
        </div>
    )
}
