// Definition of AntDesign Constants

export default {
    theme: {
        token: {
            colorPrimary: '#05262b',
            fontFamily: 'Raleway'
        },
    },
};
